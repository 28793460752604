<!--
  - MyTh Ahmed Faiz Copyright © 2016-2022 All rights reserved.
  - Email: mythpe@gmail.com
  - Mobile: +966590470092
  - Website: https://www.4myth.com
  -->
<template>
  <v-container fluid>
    <v-row justify="center">
      <v-col cols="auto">
        <v-icon
          :color="dataType"
          size="50"
        >
          {{ isSuccess ? 'mdi-check-circle' : 'error' }}
        </v-icon>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="auto">
        <h3 :class="`${dataType}--text`">
          {{ message }}
        </h3>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { MetaInfoMixin } from '@mixins'

export default {
  name: 'ResultApi',
  mixins: [MetaInfoMixin],
  data: () => ({}),
  computed: {
    message () {
      return this.$route?.query?.message || this.$t('messages.error')
    },
    isSuccess () {
      return this.$route?.query?.r === 'success' && this.$route?.query?.message
    },
    dataType () {
      return this.isSuccess ? 'success' : 'error'
    }
  },
  mounted () {
  }
}
</script>
